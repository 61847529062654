@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~antd/dist/antd.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

html,
body {
  font-family: 'Inter', sans-serif !important;
}

svg {
  vertical-align: initial !important;
}

table .ant-select-multiple .ant-select-selector {
  border: none;
  background: transparent;
}

.ant-table-content > table {
  font-size: 12px !important;
}

table .ant-select {
  font-size: 12px !important;
  width: 100%;
}

table .ant-picker-input > input {
  font-size: 12px !important;
}

.ant-table-footer {
  background: #fafafa;
}

.ant-dropdown-menu {
  border-radius: 8px;
}

.ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

.sidebar-table .panel-collapse {
  margin: 0px -18px 0px -24px;
  max-height: unset;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.sidebar-table .panel-close {
  max-height: 0;
}

.sidebar-table .panel-group {
  padding-left: 24px;
  padding-right: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  line-height: normal;
}

.ic-right-align {
  visibility: hidden;
  text-align: right;
}

.row-menu-bar:hover > .ic-right-align {
  visibility: visible;
}

.view-menu-bar {
  padding-left: 44px;
  padding-right: 18px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.view-active {
  background: rgba(230, 245, 252, 1);
  color: #049fdc;
}

.view-active .ic-right-align {
  visibility: visible;
}

.ant-menu-item .anticon {
  margin-right: 0px !important;
}

.site-layout {
  margin: 0px 22px;
  background: white;
}

.table-view td {
  border-right: 0.5px dashed rgba(0, 0, 0, 0.1) !important;
}

.table-view th:first-child {
  border-top-left-radius: 10px !important;
}

.table-view th:last-child {
  border-top-right-radius: 10px !important;
}

.table-view th:first-child,
.table-view td:first-child,
.table-view th:last-child {
  border-right: none !important;
}

.table-view .ant-table-footer {
  background: transparent;
}

.menu-bar ul {
  color: white;
  background: #025575 !important;
  border: none !important;
}

.menu-bar .ant-layout-sider-trigger {
  color: white;
  background: #025575 !important;
  border-top: 1px solid #237295 !important;
}

.table-active {
  background: #e6f5fc;
}

.table-rename {
  border-radius: 6px;
}

.modal-confirm .ant-modal-content {
  background: #f9f9fa;
  border-radius: 8px;
}

.json-field .ant-modal-confirm-content {
  margin-left: 0 !important;
}

/* Options menu */
.options-menu .ant-popover-inner-content {
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}

.options-menu .ant-menu-item {
  margin: 0 !important;
  padding-left: 14px !important;
}

.options-menu .ant-menu-item .anticon {
  margin-right: 8px !important;
}

.options-menu .ant-menu-item:hover {
  background: #e6f5fc;
  color: inherit;
  transition: background 200ms ease-out;
}

.workflow-action-item-panel .ant-collapse-header {
  padding: 8px 40px 8px 0 !important;
}

/* More options */
.more-options {
  visibility: hidden;
}
.has-more-options:hover .more-options,
.more-options:focus {
  visibility: visible;
}

.ReactVirtualized__Grid {
  outline: none;
}

.TopRightGrid_ScrollWrapper .ReactVirtualized__Grid__innerScrollContainer {
  border-radius: 8px 0 0 0;
}

.project-card-popover .ant-popover-inner-content {
  padding: 0;
  overflow: hidden;
}

.no-qode-frame-container {
  height: calc(100vh - 64px);
}

.no-qode-frame {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.width-260-px {
  width: 260px;
}

.width-100 {
  width: 100%;
}

.ant-image-preview-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Card  */
.card-white {
  @apply bg-white rounded-md max-w-full;
}
.card-white--md {
  width: 552px;
  @apply p-14;
}
.card-white--sm {
  width: 408px;
  @apply p-10;
}

/* Form  */
.form-input-control {
  height: 48px;
  @apply py-3 px-3 text-sm w-full bg-white border border-gray-300 rounded-md focus:outline-none focus:bg-white focus:text-gray-900;
}

.form-input-control--icon-left {
  @apply pl-10;
}

.form-input-control--icon-right {
  @apply pr-10;
}

.label-required::after {
  content: '*';
  @apply text-red-600 inline-block;
}
